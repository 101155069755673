var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"}),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center",attrs:{"lg":"7"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center mt-5 "},[_c('b-img',{staticClass:"mt-5",staticStyle:{"ustify-self":"bottom"},attrs:{"fluid":"","src":_vm.logo_siembal,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"d-flex auth-bg px-2 p-lg-5 ",attrs:{"lg":"5"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto  mt-5",attrs:{"sm":"8","md":"6","lg":"12"}},[(_vm.st == '1')?_c('registrasi-info',{attrs:{"info":_vm.rs}}):_vm._e(),(_vm.st =='0')?_c('div',[_c('b-card-title',{staticClass:"font-weight-bold mb-1 text-center  mt-5",attrs:{"title-tag":"h2"}},[_c('h4',{staticClass:"text-primary"},[_vm._v(" Registrasi Akun Pelajar Pelopor Keselamatan 👋 ")])]),_c('b-card-text',{staticClass:"mb-2"}),_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Nama Lengkap","label-for":"login-nama_lengkap"}},[_c('validation-provider',{attrs:{"name":"Nama Lengkap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-nama_lengkap","state":errors.length > 0 ? false:null,"name":"login-nama_lengkap","placeholder":"Nama Lengkap"},model:{value:(_vm.input.nm_lengkap),callback:function ($$v) {_vm.$set(_vm.input, "nm_lengkap", $$v)},expression:"input.nm_lengkap"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,672450590)})],1),_c('b-form-group',{attrs:{"label":"Nomor Hp/WA","label-for":"login-no_tlp"}},[_c('validation-provider',{attrs:{"name":"Nomor Tlp/WA","rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-no_tlp","state":errors.length > 0 ? false:null,"type":"number","name":"login-no_tlp","placeholder":"Nomor Tlp/WA"},model:{value:(_vm.input.no_hp),callback:function ($$v) {_vm.$set(_vm.input, "no_hp", $$v)},expression:"input.no_hp"}}),_c('small',{staticClass:"text-warning"},[_c('i',[_vm._v("** No HP/WA akan di gunakan menjadi username saat login")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2102419079)})],1),_c('b-form-group',{attrs:{"label":"Jenis Kelamin","label-for":"login-no_tlp"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-radio',{attrs:{"name":"some-radios","value":"Laki-laki"},model:{value:(_vm.input.jenis_kelamin),callback:function ($$v) {_vm.$set(_vm.input, "jenis_kelamin", $$v)},expression:"input.jenis_kelamin"}},[_vm._v(" Laki-laki ")])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-radio',{attrs:{"name":"some-radios","value":"Perempuan"},model:{value:(_vm.input.jenis_kelamin),callback:function ($$v) {_vm.$set(_vm.input, "jenis_kelamin", $$v)},expression:"input.jenis_kelamin"}},[_vm._v(" Perempuan ")])],1)],1)],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Password")])]),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.input.password),callback:function ($$v) {_vm.$set(_vm.input, "password", $$v)},expression:"input.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,622783636)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Ketik Ulang Password")])]),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.input.password2),callback:function ($$v) {_vm.$set(_vm.input, "password2", $$v)},expression:"input.password2"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1881023942)})],1),(_vm.input.password2 == _vm.input.password & _vm.input.jenis_kelamin != null)?_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""},on:{"click":function($event){return _vm.validationForm()}}},[_c('feather-icon',{attrs:{"icon":"UserCheckIcon","size":"15"}}),_vm._v(" Registrasi ")],1):_vm._e(),_c('small',[_c('i',[_vm._v("Tombol registrasi akan aktif jika semua data sudah terisi dengan benar")])])],1)],1),_c('router-link',{attrs:{"to":"/"}},[_c('b-button',{staticClass:"mt-1",attrs:{"type":"submit","variant":"warning","block":""},on:{"click":function($event){return _vm.validationForm()}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftCircleIcon","size":"15"}}),_vm._v(" Kembali Halama Login ")],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }